import {
  accesoriesDto,
  customerDto,
  dealsDto,
  models,
  quoteDto,
} from './standard-quote.model';

export class BodyCalculate {
  nameCompany: string | undefined;
  financialProduct: string;
  currency: string | undefined;
  calculationType: string;
  maturityType: string;
  quantity: number | undefined;
  unitPrice: number | undefined;
  term: number;
  taxFree: boolean;
  customerRate: number;
  confirmationExpensesAmount: number;
  lifeInsuranceAmount: number;
  insuranceAmount: number | null;
  openingFeeType: string;
  openingFeePercentage: number;
  openingFee: number;
  balloonType: string;
  balloonPercentage: number;
  balloon: number;
  gracePeriodApplyTo: string;
  gracePeriod: number;
  downPaymentTaxType: string;
  downPaymentTax: number;
  downPayment: number;
  specialPayments: any;
  grace: any;
  financialSetupId: string;
  financialConditionId: string;
  modeId: number | null;
  personType: string | undefined;
  purchasePercentage: number | null;

  constructor(data: any, formData: quoteDto | null, models: models | null, customer: customerDto | null, amount = null,
              standardQuoteModel: models | undefined ) {
    this.personType = customer?.personType;
    this.modeId =  models ? models.id : null;
    this.nameCompany = formData?.companyName;
    this.financialProduct = data.financialSetup.financialProduct;
    this.currency = formData?.model.currency;
    this.calculationType = data.financialSetup.calculationType;
    this.maturityType = data.financialSetup.paymentType;
    this.quantity = formData?.quantity;
    this.unitPrice = standardQuoteModel ? standardQuoteModel.price : this.getPrice(formData);
    this.term = data.financialSetup.financialCondition.term;
    this.taxFree = false;
    this.customerRate =
      data.financialSetup.financialCondition.nominalRate;
    this.confirmationExpensesAmount = 3886;
    this.lifeInsuranceAmount = 0.02146;
    this.insuranceAmount = amount;
    this.openingFeeType = data.financialSetup.openingFeeType;
    this.openingFeePercentage = data.financialSetup.openingFeeTax;
    this.openingFee = data.financialSetup.openingFee;
    this.balloonType = data.financialSetup.balloonType;
    this.balloonPercentage = data.financialSetup.balloonPercentage;
    this.balloon = data.financialSetup.balloon;
    this.gracePeriodApplyTo = 'CAPITAL';
    this.gracePeriod = 1;
    this.downPaymentTaxType = data.financialSetup.downPaymentTaxType;
    this.downPaymentTax = data.financialSetup.downPaymentTax;
    this.downPayment = data.financialSetup.downPayment;
    this.specialPayments = data.specialPayments;
    this.grace = data.grace;
    this.financialSetupId = data.financialSetup.id;
    this.financialConditionId = data.financialSetup.financialCondition.id;
    this.purchasePercentage = data.financialCondition.purchasePercentage;
  }

  getPrice(formData: quoteDto | null) {
    let price = 0;
    if (formData?.model) {
      price = formData.model.price;
      if (formData.accessories) {
        formData.accessories.map((i) => (price += i.price));
      }
    }
    return price;
  }
}

export class BodySave {
  id: number;
  creationDate?: string;
  creationUser?: number;
  customer: customerDto;
  model: models;
  standardQuoteDeals: dealsDto[];
  accessories: accesoriesDto[];
  dealAmount: number;
  status: string;
  updateUser?: number;
  updatedDate?: string;
  companyName: string;
  quoteName: string;
  dealerId: number;
  quantity: number;
  wholeSaleId: number

  constructor(data: any) {
    this.id = data.id;
    this.customer = data.customer;
    this.model = data.model;
    this.standardQuoteDeals = data.standardQuoteDeals.map(
      (i: any) => new Deal(i)
    );
    this.accessories = data.accessories;
    this.dealAmount = data.dealAmount;
    this.status = data.status;
    this.companyName = data.companyName;
    this.quoteName = data.quoteName;
    this.dealerId = data.dealer ?  data.dealer.id : data.dealerId;
    this.quantity = data.quantity;
    this.wholeSaleId = data.wholeSale? data.wholeSale.id : data.wholeSaleId;
    if (data.id) {
      this.creationDate = data.creationDate;
      this.updatedDate = data.updatedDate;
      this.creationUser = data.creationUser;
      this.updateUser = data.updateUser;
      if (data.standardQuoteModel)
        this.model.price = data.standardQuoteModel?.price;
    }
  }
}

export class Deal {
  id: number;
  creationDate?: string;
  creationUser?: number;
  updateUser?: number;
  updatedDate?: string;
  financialSetupName: string;
  financialProduct: string;
  currency: string;
  classification: boolean;
  calendarType: string;
  paymentFormationType: string;
  allowPaymentDay: boolean;
  allowCapitalGrace: boolean;
  allowInterestGrace: boolean;
  allowOpeningFee: boolean;
  openingFeeType: string;
  purchaseOptionType: string;
  warrantyDeposit: string;
  companyName: string;
  financialSetupDescription: string;
  paymentType: string;
  downPaymentTaxType: string;
  openingFee: number;
  openingFeeTax: number;
  downPayment: number;
  downPaymentTax: number;
  isSelected: boolean;
  financialCondition: any;
  financialSetup: any;
  payments: any;
  result: any;
  standardFinancialSetup: any;
  standardQuoteCondition: any;
  specialPayments: any;
  grace: any;
  insurance: any;

  constructor(data: any) {
    this.id = data.id;
    this.financialSetupName = data.financialSetupName;
    this.financialProduct = data.financialProduct;
    this.currency = data.currency;
    this.classification = data.classification;
    this.calendarType = data.calendarType;
    this.paymentFormationType = data.paymentFormationType;
    this.allowPaymentDay = data.allowPaymentDay;
    this.allowCapitalGrace = data.allowCapitalGrace;
    this.allowInterestGrace = data.allowInterestGrace;
    this.allowOpeningFee = data.allowOpeningFee;
    this.openingFeeType = data.openingFeeType;
    this.purchaseOptionType = data.purchaseOptionType;
    this.warrantyDeposit = data.warrantyDeposit;
    this.companyName = data.companyName;
    this.financialSetupDescription = data.financialSetupDescription;
    this.paymentType = data.paymentType;
    this.downPaymentTaxType = data.downPaymentTaxType;
    this.openingFee = data.openingFee;
    this.openingFeeTax = data.openingFeeTax;
    this.downPayment = data.downPayment;
    this.downPaymentTax = data.downPaymentTax;
    this.isSelected = data.isSelected;
    this.financialCondition = data.financialCondition;
    this.financialSetup = data.financialSetup;
    this.payments = data.payments;
    this.specialPayments = data.specialPayments;
    this.grace = data.grace;
    this.insurance = data.insurance;
    this.result = data.result;
    this.standardFinancialSetup = data.standardFinancialSetup;
    this.standardQuoteCondition = data.standardQuoteCondition;
    if (data.id) {
      this.creationDate = data.creationDate;
      this.updatedDate = data.updatedDate;
      this.creationUser = data.creationUser;
      this.updateUser = data.updateUser;
    }
  }
}
