import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiService } from '@app/services/api.base.service';
import { environment } from '@env/environment.local';
import {
  FinancialProductEnum,
  priceTypeEnum,
  modelTypeEnum,
  quoteDto,
  calculateDto,
  TaxTypeEnum,
  residualValue,
  paymentTypeEnum, models, customerDto,
} from './model/standard-quote.model';
import { CreateCoverage } from './model/standard-insurance.model';
import { BodyCalculate, BodySave } from './model/model-body.model';
import {ResponseDTO} from "@app/core/model/CoreModel";

@Injectable({
  providedIn: 'root',
})
export class StandardQuoteService {
  productType = [
    { description: 'Autobuses', productType: modelTypeEnum.autobuses },
    { description: 'Camiones', productType: modelTypeEnum.camiones },
    { description: 'Tractos', productType: modelTypeEnum.tractos },
   // { description: 'Sprinter', productType: modelTypeEnum.sprinter },
    { description: 'Accesorios', productType: modelTypeEnum.accesorios },
  ];

  priceType = [
    {
      description: 'Precio valor comercial',
      priceType: priceTypeEnum.priceNormal,
    },
    // { description: 'Precio normal', priceType: priceTypeEnum.priceNormal },
  ];

  currencies = [
    { type: 'MXN', value: 'MXN' },
    { type: 'USD', value: 'USD' },
  ];

  typeStatus: any[] = [
    { label: 'Borrador', value: 2 },
    { label: 'Editada', value: 2 },
    { label: 'Generada', value: 1 },
  ];

  financialProductsList = [
    { name: 'Crédito directo', value: FinancialProductEnum.directCredit },
    { name: 'Arrendamiento financiero', value: FinancialProductEnum.financialLeasing },
    { name: 'Arrendamiento puro', value: FinancialProductEnum.pureLease },
    { name: 'Crédito directo Balloon', value: FinancialProductEnum.directCreditGlobe },
  ];

  useListTracts = [{ description: 'Carga', value: 'LOAD' }];

  useListBuses = [
    { description: 'Pasaje local', value: 'LOCAL_PASSAGE' },
    { description: 'Pasaje foráneo', value: 'FOREIGN_PASSAGE' },
    { description: 'Turismo', value: 'TOURISM' },
    { description: 'Escolar o personal', value: 'SCHOOL_PERSONAL' },
  ];

  formData: quoteDto | null;
  duplicateDeal: any | null;

  taxTypeEnum = TaxTypeEnum;
  residualValue: residualValue [] = [];

  updatedFormData: Subject<any> = new Subject<any>();

  constructor(public api: ApiService, public httpClient: HttpClient) {}

  updateForm(value: any) {
    this.formData = value;
  }

  getDealersInStandarQuote(): Observable<any> {
    //listado de dealers para el cotizador
    return this.api.get(`${environment.API_DEALEARS_SERVICE}/dealers`);
  }


  getQuotes(data: any): Observable<any> {
    // Listado de cotizaciones
    return this.api.post(
      `${environment.API_QUOTE_SERVICE}/standard-quote`,
      data
    );
  }

  getById(id: number): Observable<any> {
    // Listado de cotizaciones
    return this.api.get(
      `${environment.API_QUOTE_SERVICE}/standard-quote/${id}`
    );
  }

  //#region DELETE
  deleteQuote(idQuote: number): Observable<any> {
    return this.api.delete(
      `${environment.API_QUOTE_SERVICE}/standard-quote/${idQuote}`,
      false
    );
  }

  duplicateStandardQuoteById(idQuote: number): Observable<ResponseDTO> {
    return this.api.post(
      `${environment.API_QUOTE_SERVICE}/standard-quote/${idQuote}/clone`,
      false
    );
  }

  deleteDeal(idQuote: number, idDeal: any): Observable<any> {
    // Eliminacion de cotizacion
    return this.api.delete(
      `${environment.API_QUOTE_SERVICE}/standard-quote/${idQuote}/deals/${idDeal}`,
      false
    );
  }

  deletePayments(idQuote: number, idDeal: any) {
    return this.api.delete(
      `${environment.API_QUOTE_SERVICE}/standard-quote/${idQuote}/deals/${idDeal}/payments`,
      false
    );
  }

  deleteSpecialPayments(idQuote: number, idDeal: any, idSpecialPayment: any) {
    return this.api.delete(
      `${environment.API_QUOTE_SERVICE}/standard-quote/${idQuote}/deals/${idDeal}/special-payments/${idSpecialPayment}`,
      false
    );
  }

  deleteGrace(idQuote: number, idDeal: any, gracePeriodId: any) {
    return this.api.delete(
      `${environment.API_QUOTE_SERVICE}/standard-quote/${idQuote}/deals/${idDeal}/grace-period/${gracePeriodId}`,
      false
    );
  }

  deleteInsurance(idQuote: number, idDeal: any, idInsurance: any) {
    return this.api.delete(
      `${environment.API_QUOTE_SERVICE}/standard-quote/${idQuote}/deals/${idDeal}/insurances/${idInsurance}`,
      false
    );
  }

  deleteAccessories(idQuote: number, idAccessories: any) {
    return this.api.delete(
      `${environment.API_QUOTE_SERVICE}/standard-quote/${idQuote}/accessories/${idAccessories}`,
      false
    );
  }

  deleteAllDeals(idQuote: number) {
    return this.api.delete(
      `${environment.API_QUOTE_SERVICE}/standard-quote/${idQuote}/deals`,
      false
    );
  }

  deleteAllGracePeriod(idQuote: number, idDeal: any) {
    return this.api.delete(
      `${environment.API_QUOTE_SERVICE}/standard-quote/${idQuote}/deals/${idDeal}/grace-period`,
      false
    );
  }

  deleteAllSpecialPayments(idQuote: number, idDeal: any) {
    return this.api.delete(
      `${environment.API_QUOTE_SERVICE}/standard-quote/${idQuote}/deals/${idDeal}/special-payments`,
      false
    );
  }
  //#endregion

  saveDraft(): Observable<any> {
    let model = new BodySave(this.formData);
    //Guardar borrador
    return this.api.post(
      `${environment.API_QUOTE_SERVICE}/standard-quote/draft`,
      model
    );
  }

  updateQuote(standardQuoteId: any): Observable<any> {
    let model = new BodySave(this.formData);
    // Guardado de cotizacion
    return this.api.put(
      `${environment.API_QUOTE_SERVICE}/standard-quote/${standardQuoteId}`,
      model
    );
  }

  getModels(): Observable<any> {
    //Obtener productos
    return this.api.get(`${environment.API_QUOTE_SERVICE}/models`);
  }

  getModelsByFilter(body: any): Observable<any> {
    //Obtener productos
    return this.api.post(`${environment.API_QUOTE_SERVICE}/models/all-quote`, body);
  }
  getPackage(): Observable<any> {
    //obtener paquetes financieros
    return this.api.get(`${environment.API_QUOTE_SERVICE}/financial-setup`);
  }

  /**
   * Obtener paquetes financieros por medio de un filtro
   * @param filter
   * @returns list of financial setups
   */
  getPackagesFromList(filter: any): Observable<any> {
    const options = {
      params: filter
    }
    return this.api.getWithOptions(`${environment.API_QUOTE_SERVICE}/financial-setup/filter`, options );
  }

  calculateQuote(data: calculateDto, models: models, customer: customerDto, standardQuoteModel: models | undefined): Observable<any> {
    let model = new BodyCalculate(data, this.formData, models, customer, null,standardQuoteModel); //Generar cotizacion
    return this.api.post(
      `${environment.API_QUOTE_SERVICE}/standard-quote/calculate`,
      model
    );
  }

  pdfStandardQuote(idQuote: number): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    return this.httpClient.post(
      `${environment.API_QUOTE_SERVICE}/quote/${idQuote}/pdf`,
      null,
      { headers: headers, responseType: 'blob' }
    );
  }

  calculateStandardQuoteOpenJson(data: any): Observable<any> {
    return this.api.post(
      `${environment.API_QUOTE_SERVICE}/standard-quote/calculate/download`,
      data
    );
  }

  //#region Coverage

  getCoverage(data: any): Observable<any> {
    //datos de configuracion seguro
    return this.api.post(
      `${environment.API_QUOTE_SERVICE}/coverage-quote/standard/get-coverage`,
      data
    );
  }

  getRecalculateCoverage(dataRecalculate: any): Observable<any> {
    return this.api.post(
      `${environment.API_QUOTE_SERVICE}/coverage-quote/recalculate-coverage`,
      dataRecalculate
    );
  }

  // createQuote(data: any): Observable<any> {
  //   const now = new Date();
  //   let date = `${now.getFullYear()}-${
  //     now.getMonth() + 1
  //   }-${now.getDate()}T${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`;
  //   createQuoteBody.panel = data;
  //   createQuoteBody.quote.startValidity = date;

  //   //cotizar seguro
  //   return this.api.post(
  //     `${environment.API_QUOTE_SERVICE}/coverage-quote/create-quote`,
  //     createQuoteBody
  //   );
  // }

  createQuote(data: CreateCoverage): Observable<any> {
    return this.api.post(
      `${environment.API_QUOTE_SERVICE}/coverage-quote/standard/create-quote`,
      data
    );
  }

  getQuoteCoverage(id: number): Observable<any> {
    //obtener cotizacion de seguro
    let data = {
      requestId: id,
      paymentMethodId: 72,
    };
    return this.api.post(
      `${environment.API_QUOTE_SERVICE}/coverage-quote/get-quote`,
      data
    );
  }

  udiCoverage(data: any) {
    return this.api.post(
      `${environment.API_QUOTE_SERVICE}/coverage-quote/standard/udi`,
      data
    );
  }
  //#endregion

  getUdisParametric(data: any): Observable<any> {
    return this.api.post(
      `${environment.API_QUOTE_SERVICE}/coverage-quote/standard/udis-parametric`,
      data
    );
  }

  getStatesParametric(data: any): Observable<any> {
    return this.api.post(
      `${environment.API_QUOTE_SERVICE}/states/state-parametric`,
      data
    );
  }
  getZipCode(state: string) {
    return this.api.get(`${environment.API_QUOTE_SERVICE}/states/${state}`);
  }

  getTaxType(taxType: TaxTypeEnum) {
    if (taxType == this.taxTypeEnum.invoiceWithoutTax) {
      return 'Valor sin iva';
    } else if (taxType == this.taxTypeEnum.invoiceWithTax) {
      return 'Valor con iva';
    } else if (taxType == this.taxTypeEnum.fixedAmount) {
      return 'Monto fijo';
    } else if (taxType == this.taxTypeEnum.amountToFinance) {
      return 'Monto a financiar';
    }
    return '';
  }

  getPaymentType(paymentType: paymentTypeEnum) {
    if (paymentType == paymentTypeEnum.expiration) {
      return 'Vencimiento';
    }
    return '';
  }

  getFinancialProduct(financialProductType: FinancialProductEnum) {
    if (financialProductType == FinancialProductEnum.directCredit) {
      return 'Crédito directo';
    } else if (financialProductType == FinancialProductEnum.financialLeasing) {
      return 'Arrendamiento financiero';
    } else if (financialProductType == FinancialProductEnum.pureLease) {
      return 'Arrendamiento puro';
    }else if (financialProductType == FinancialProductEnum.directCreditGlobe) {
        return 'Crédito directo Balloon';
    } else {
      return '';
    }
  }

  downloadPdf(standardQuoteId: number): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    return this.httpClient.post(
      `${environment.API_QUOTE_SERVICE}/standard-quote/${standardQuoteId}/pdf`,
      null,
      { headers: headers, responseType: 'blob' }
    );
  }

  getAllDealers(): Observable<any> {
    return this.api.get(`${environment.API_DEALEARS_SERVICE}/dealers`);
  }

  getRelationalName(dealerId: number): Observable<any>{
    return this.api.get(`${environment.API_DEALEARS_SERVICE}/dealers/${dealerId}/relational-name`);
  }

  getParameters() {
    return this.api.get(`${environment.API_QUOTE_SERVICE}/financial-setup/parameters`);
  }


  getPackagesByFilter(filter: any) {

    return this.api.get(`${environment.API_QUOTE_SERVICE}/financial-setup/filter`, filter)

      throw new Error('Method not implemented.');
  }


}
