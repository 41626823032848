import {
  AfterViewChecked,
  Component,
  computed,
  ElementRef,
  HostListener,
  inject,
  input,
  output,
  signal,
  viewChild,
} from '@angular/core';

@Component({
  selector: 'app-taggable-multi-select',
  templateUrl: './taggable-multi-select.component.html',
  styleUrls: ['./taggable-multi-select.component.scss'],
})
export class TaggableMultiSelectComponent implements AfterViewChecked {
  private readonly elementRef = inject(ElementRef);
  readonly label = input<string>('Elija una opcion');
  readonly placeholder = input<any>('Buscar...');
  readonly options = input<any[]>([]);
  readonly displayKey = input<string>('description');
  readonly selectionChange = output<any[]>();

  readonly selectedOptions = signal<any[]>([]);
  readonly open = signal(false);
  readonly isPositioned = signal(false);
  readonly openUp = signal(false);
  readonly searchQuery = signal('');

  // Se actualiza automaticamente si "searchQuery" o "options" cambian de valor
  readonly filteredOptions = computed(() => {
    const query = this.searchQuery().toLowerCase();
    if (!query?.trim()) return this.options();

    return this.options().filter((option) =>
      this.getDisplayValue(option).toLowerCase().includes(query)
    );
  });

  readonly input = viewChild<ElementRef<HTMLInputElement>>('outComesInput');
  readonly listBox = viewChild<ElementRef<HTMLDivElement>>('listBox');
  private needsPositionAdjustment = false;

  ngAfterViewChecked(): void {
    if (this.needsPositionAdjustment) {
      this.adjustDropdownPosition();
      this.isPositioned.set(true);
    } else {
      this.isPositioned.set(false);
    }
  }
  // Cierra la lista automaticamente si clickea fuera del componente y la lista se encuentra abierta
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const clickedInside = this.elementRef.nativeElement.contains(event.target);
    if (!clickedInside && this.open()) {
      this.closeDropdown();
    }
  }

  public onSelect(updatedOption: any): void {
    const updatedValue = this.getDisplayValue(updatedOption);
    if (this.hasSelected(updatedOption) && updatedValue === 'Todos') return;
    const isSelectedAll = this.selectedOptions().some(
      (option: any) => this.getDisplayValue(option) === 'Todos'
    );
    if (
      (isSelectedAll && updatedValue !== 'Todos') ||
      (!isSelectedAll && updatedValue === 'Todos')
    ) {
      this.selectedOptions.set([updatedOption]);
    } else if (
      !isSelectedAll &&
      updatedValue !== 'Todos' &&
      !this.hasSelected(updatedOption)
    ) {
      this.selectedOptions.update((prev) => [...prev, updatedOption]);
    }

    this.selectionChange.emit(this.selectedOptions());
    this.resetAndClose();
  }

  public onRemove(option: any): void {
    this.selectedOptions.update((prev) =>
      prev.filter((item) => item !== option)
    );
    this.selectionChange.emit(this.selectedOptions());
    this.resetAndClose();
  }

  public onChangeQuery(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value;
    this.searchQuery.set(inputValue);
  }

  onKeyDown(event: KeyboardEvent): void {
    const inputElement = this.input()?.nativeElement;
    const key = event.key;
    if (!inputElement || key !== 'Escape') return;
    event.preventDefault();
    this.resetAndClose();
    inputElement.blur();
  }

  public toggleDropdown(): void {
    if (!this.open()) {
      this.needsPositionAdjustment = true;
    } else {
      this.needsPositionAdjustment = false;
    }
    this.open.update((prev) => !prev);
  }

  public openDropdown() {
    // Auto ajusto la posicion luego de abrirla (Para renderizar primero el elemento y obtener su height)
    this.needsPositionAdjustment = true;
    this.open.set(true);
  }

  public closeDropdown() {
    this.open.set(false);
  }

  public getDisplayValue(option: any) {
    return option?.[this.displayKey()] ?? 'N/A';
  }
  public getDefaultTodos() {
    return { [this.displayKey()]: 'Todos' };
  }

  public hasSelected(option: any) {
    return this.selectedOptions().includes(option);
  }

  /*
  Limpia el input y cierra la lista
  */
  private resetAndClose(): void {
    this.searchQuery.set('');
    this.closeDropdown();
  }

  /*
  Auto ajusta la posicion de la lista segun el alto disponible de la ventana
  */
  private adjustDropdownPosition(): void {
    const inputElement = this.input()?.nativeElement;
    const listElement = this.listBox()?.nativeElement;

    if (!inputElement || !listElement) return;
    // Alto maximo definido en css
    const MAX_HEIGHT = 256;
    // Margen de seguridad para evitar que quede muy pegado al borde
    const SAFE_MARGIN = 18;
    const inputRect = inputElement.getBoundingClientRect();
    const { innerHeight } = window;

    // Obtener el alto real de la lista
    const listHeight = listElement.scrollHeight;
    // Aplicar el límite máximo al alto de la lista
    const actualDropdownHeight = Math.min(listHeight, MAX_HEIGHT);

    // Calcular espacio disponible arriba y abajo
    const spaceBelow = innerHeight - inputRect.bottom;
    const spaceAbove = inputRect.top;

    // Determinar si debe abrirse hacia arriba
    // Se abre hacia arriba si:
    // 1. No hay suficiente espacio abajo Y
    // 2. Hay suficiente espacio arriba
    this.openUp.set(
      spaceBelow < actualDropdownHeight + SAFE_MARGIN &&
        spaceAbove >= actualDropdownHeight
    );
  }
}
