<app-preloader *ngIf="isLoading"></app-preloader>
<app-notifications></app-notifications>
<div class="modalWrapper" id="new-mailshot">
  <div class="modal">
    <a class="close" role="button" (click)="onClose()">Cerrar</a>
    <h1>{{ "New mailshot" | translate }}</h1>

    <form [formGroup]="form">
      <div class="modalContent">
        <div class="controlWrapper">
          <label
            >{{ "Title" | translate }}<span class="required">*</span></label
          >
          <input
            type="text"
            placeholder=" "
            formControlName="name"
            maxlength="100"
            [ngClass]="{
              error: !form.get('name')?.valid && form.get('name')?.dirty
            }"
          />

          <ng-container
            *ngIf="
              form.get('name')?.hasError('required') && form.get('name')?.dirty
            "
          >
            <span class="alert">{{ "Required title" | translate }}</span>
          </ng-container>
        </div>

        <app-input-file-select
          label="Attach mailshot"
          [isValidSelected]="!fileSelectHasError()"
          (onSelected)="onFileSelected($event)"
          (onRemoved)="onRemoveFile()"
        />

        <div class="controlWrapper">
          <app-taggable-multi-select
            label="Receiver dealers"
            placeholder="Seleccionar distribuidores"
            [options]="dealers()"
            displayKey="description"
            (selectionChange)="selectDealersCustom($event)"
          />
        </div>
        <div class="controlWrapper">
          <app-taggable-multi-select
            label="Receiver internals"
            placeholder="Seleccionar personal interno"
            [options]="internals()"
            displayKey="description"
            (selectionChange)="selectInternalsCustom($event)"
          />
        </div>
      </div>
    </form>

    <!-- Acciones para modal -->
    <div class="actions">
      <button (click)="onClose()" type="button" class="secondary">
        {{ "Cancel" | translate }}
      </button>
      <button
        [disabled]="!isEnabled"
        (click)="save()"
        type="submit"
        class="primary"
      >
        {{ "Create" | translate }}
      </button>
    </div>
  </div>
</div>
