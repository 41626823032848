<!-- <div class="controlWrapper"> -->
<!-- <label *ngIf="label" for="">{{ label }}</label> -->
<input
  type="text"
  class="form-control"
  placeholder="dd/mm/aaaa"
  [ngClass]="{ error: hasError }"
  [(ngModel)]="selectedDate"
  (ngModelChange)="onDateChange($event)"
  bsDatepicker
/>
<!-- </div> -->
