import {
  Component,
  inject,
  OnInit,
  TemplateRef,
  viewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '@app/components/base/base.component';
import { MenusServices } from '@app/services/menus.service';
import { MailshotService } from '../mailshot.service';
import { NotificationService } from '@app/services/notifications.service';
import { AuthService } from '@app/common/auth.service';
import { PageEvent } from '@angular/material/paginator';
import { queryParamsMailshot } from '../model/mailshot.model';
import { DOCUMENT } from '@angular/common';
import { ApiService } from '@app/services/api.base.service';
import { EntitiesServices } from '@app/services/entities.service';
import { environment } from '@env/environment.local';
import { ModalService } from '@app/components/modal/modal.service';

@Component({
  selector: 'app-mailshot',
  templateUrl: './mailshot.component.html',
  styleUrls: ['./mailshot.component.css'],
})
export class MailshotComponent extends BaseComponent implements OnInit {
  modalMailshot = viewChild.required<TemplateRef<any>>('modalMailShot');
  document = inject(DOCUMENT);
  module = '';
  role: string;
  isMobile: boolean = false;
  data: any[] = [];
  isLoading: boolean = true;
  select = this.service.select;
  input: any[] = [{ placeholder: 'Buscar circular', value: '' }];
  total: number = 0;
  pageSize: number = environment.pageSize;
  pageIndex: number = 1;

  params: queryParamsMailshot = {
    page: this.pageIndex,
    pageSize: this.pageSize,
    description: '',
    year: null,
    isDownload: null,
  };

  hasData: boolean = true;
  internals: any[] = [];
  dealers: any[] = [];
  isDisabled: boolean = true;
  profiles: any[] = [];
  isOpen: boolean = false;
  constructor(
    route: ActivatedRoute,
    menuService: MenusServices,
    private service: MailshotService,
    private notificationsService: NotificationService,
    private authService: AuthService,
    public api: ApiService,
    private readonly modalService: ModalService,
    private entitiesService: EntitiesServices
  ) {
    super(route, menuService);
    this.module = this.sendDataToMenu();
  }

  ngOnInit(): void {
    this.isMobile = this.mobile();
    this.getModuleAndRole();
    this.getMailshot();
    this.getProfiles();
  }

  openActions() {
    this.isOpen = !this.isOpen;
  }

  getModuleAndRole() {
    this.authService.currentModule.forEach((i: any) => {
      if (i['modulo'] == this.module) {
        this.role = i['role'];
      }
    });
  }

  getTableRowClass(item: any): string {
    if (
      !item.downloadedByUser &&
      this.role !== this.rolesInternal.administrator
    ) {
      return 'row unread';
    } else if (this.role === this.rolesInternal.administrator) {
      return 'row';
    }
    return 'row'; // Si ninguna condición se cumple, no se aplica ninguna clase adicional
  }

  getMailshot() {
    this.isLoading = true;
    if (this.role == this.rolesInternal.administrator) {
      this.service.listMailshotAll(this.params).subscribe({
        next: (r) => {
          this.data = r.data;
          this.total = r.totalElements;
          this.pageIndex = r.page - 1;
          this.isLoading = false;
          this.hasData = r.data.length > 0;
        },
        error: (r) => {
          this.notificationsService.showMessage(
            this.msgFailedConnection,
            'error'
          );
          this.isLoading = false;
        },
      });
    } else {
      this.service.listMailshot(this.params).subscribe({
        next: (r) => {
          this.data = r.data;
          this.total = r.totalElements;
          this.pageIndex = r.page - 1;
          this.isLoading = false;
          this.hasData = r.data.length > 0;
        },
        error: (r) => {
          this.notificationsService.showMessage(
            this.msgFailedConnection,
            'error'
          );
          this.isLoading = false;
        },
      });
    }
  }

  getProfiles() {
    this.entitiesService.listProfiles().subscribe((r) => {
      this.isDisabled = false;
      this.profiles = r.data;
      this.profiles.forEach((i: any) => {
        if (i.userType == this.userType.dealer) {
          this.dealers.push(i);
        } else {
          this.internals.push(i);
        }
      });
    });
  }

  getType(type: string) {
    switch (type) {
      case 'application/pdf':
        return 'PDF';
      case 'application/vnd.ms-excel':
        return 'XLS';
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return 'XLSX';
      case 'application/zip':
        return 'ZIP';
      case 'application/x-zip-compressed':
        return 'ZIP';
      default:
        return '';
    }
  }

  markMailshotAsDownloaded(id: number) {
    this.service.markAsDownloaded(id).subscribe({
      next: (r) => {
        this.data = this.data.map((i) => {
          return {
            ...i,
            downloadedByUser: i.id == id ? true : i.downloadedByUser,
          };
        });
      },
    });
  }

  handlePage(e: PageEvent) {
    this.params.page = e.pageIndex + 1;
    this.params.pageSize = e.pageSize;
    this.getMailshot();
  }

  filter(value: any, typeSelect: string) {
    switch (typeSelect) {
      case 'year':
        this.params.year = value;
        break;
      case 'download':
        this.params.isDownload = value;
        break;
      case 'description':
        this.params.description = value;
        break;
    }
    this.params.page = 1;
    this.getMailshot();
  }

  closeModalMailShot() {
    this.modalService.closeModal();
    this.getMailshot();
    if (this.document) {
      this.document.body.style.overflow = 'auto';
    }
  }

  openModal() {
    if (this.document) {
      this.document.body.style.overflow = 'hidden';
    }
    this.modalService.component = this.modalMailshot();
    this.modalService.openModal();
  }

  downloadAttachment(id: number, name: string, date: Date, mimeType: string) {
    this.isLoading = true;
    this.service.getAttachmentByElementId(id).subscribe({
      next: (response: any) => {
        if (this.role !== this.rolesInternal.administrator) {
          this.markMailshotAsDownloaded(id);
        }
        this.downloadFile(
          response.data,
          response.data.originalName,
          response.data.urlSource
        );
        this.isLoading = false;
      },
      error: (error: any) => {
        this.notificationsService.showMessage(
          ['Ha ocurrido un error. Vuelva a intentarlo.'],
          'error'
        );
        this.isLoading = false;
      },
    });
  }

  downloadMobile(e: any) {
    this.downloadAttachment(e.id, e.name, e.date, e.mimetype);
  }

  deleteMailshot(mailshotId: number) {
    this.isLoading = true;

    this.service.deleteMailshot(mailshotId).subscribe({
      next: (response: any) => {
        this.notificationsService.showMessage(
          ['La circular se eliminado correctamente.'],
          'success'
        );
        this.isLoading = false;
        this.getMailshot();
        setTimeout(() => {
          this.notificationsService.clearMessages();
        }, 5000);
      },
      error: (error: any) => {
        this.isLoading = false;

        this.notificationsService.showMessage(
          [
            'Ha ocurrido un error al eliminar la circular. Vuelva a intentarlo.',
          ],
          'error'
        );
      },
    });
  }
}
