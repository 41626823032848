import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './common/auth.guard';
import { AuthReverseGuard } from './common/authReverse.guard';
import { RegisterFinancialLinkComponent } from './pages/register-financial-link/register-financial-link.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginModule),
    canActivate: [AuthReverseGuard],
    data: { breadcrumb: { skip: true } },
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
    data: {
      breadcrumb: {
        title: 'INICIO',
        parent: '',
        path: 'home',
      },
    },
  },

  {
    path: 'register',
    loadChildren: () =>
      import('./pages/register/register.module').then((m) => m.RegisterModule),
    canActivate: [AuthReverseGuard],
    data: { breadcrumb: { skip: true } },
  },
  {
    path: 'register-financial-link',
    loadChildren: () =>
      import('./pages/register-financial-link/register-financial-link.module').then((m) => m.RegisterFinancialLinkModule),
    //canActivate: [AuthReverseGuard],
    data: { breadcrumb: { skip: true } },
  },
  {
    path: 'wholesale',
    loadChildren: () =>
      import('./pages/wholesale/wholesale.module').then(
        (m) => m.WholeSaleModule
      ),
    data: { breadcrumb: { skip: true } },
  },

  {
    path: 'info',
    loadChildren: () =>
      import('./pages/info-pages/info-pages.module').then(
        (m) => m.InfoPagesModule
      ),
    data: { breadcrumb: { skip: true } },
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./pages/profile/profile.module').then((m) => m.ProfileModule),
    data: { breadcrumb: { skip: true } },
  },
  {
    path: 'users',
    loadChildren: () =>
      import('./pages/users/users.module').then((m) => m.UsersModule),
    data: { breadcrumb: { skip: true } },
  },
  {
    path: 'notifications',
    loadChildren: () =>
      import(
        './pages/internal-notifications/internal-notifications.module'
      ).then((m) => m.InternalNotificationsModule),
    data: { breadcrumb: { skip: true } },
  },
  {
    path: 'marketing',
    loadChildren: () =>
      import('./pages/marketing/marketing.module').then(
        (m) => m.MarketingModule
      ),
    data: { breadcrumb: { skip: true } },
  },
  {
    path: 'mailshot',
    loadChildren: () =>
      import('./pages/mailshot/mailshot.module').then(
        (m) => m.MailshotModule
      ),
    data: { breadcrumb: { skip: true } },
  },
  {
    path: 'quote',
    loadChildren: () =>
      import('./pages/quote/quote.module').then(
        (m) => m.QuoteModule
      ),
    data: { breadcrumb: { skip: true } },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
