import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegisterFinancialLinkRoutingModule } from './register-financial-link-routing.module';
import { RegisterFinancialLinkComponent } from './register-financial-link.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AppCommonModule } from '../../components/app.common.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MatNativeDateModule,
  MAT_DATE_LOCALE,
  DateAdapter,
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [RegisterFinancialLinkComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AppCommonModule,
    RegisterFinancialLinkRoutingModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    TranslateModule,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-Es' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class RegisterFinancialLinkModule {}
