import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '@app/common/auth.service';
import { FinancialProductEnum, models } from '../../model/standard-quote.model';
import {Util} from "../../../../../core/util/Util";
import { ActivatedRoute } from '@angular/router';
import { MenusServices } from '@app/services/menus.service';
import { NotificationService } from '@app/services/notifications.service';
import { BaseComponent } from '@app/components/base/base.component';
import { StandardQuoteService } from '../../standard-quote-service.service';

@Component({
  selector: 'app-row-deal-standard-quote',
  templateUrl: './row-deal-standard-quote.component.html',
  styleUrls: ['./row-deal-standard-quote.component.css'],
})
export class RowDealStandardQuoteComponent extends BaseComponent implements OnInit {
  @Input() indexDeal: number;
  @Input() deal: any;
  @Input() idQuote: any;
  @Input() creationUser: any;
  @Input() model: models;
  @Input() status: any;
  @Input() isValidForm: boolean;
  @Input() validBranch: any;
  @Output() isSelected: EventEmitter<any> = new EventEmitter();
  @Output() edit: EventEmitter<any> = new EventEmitter();
  @Output() delete: EventEmitter<any> = new EventEmitter();
  @Output() duplicate: EventEmitter<any> = new EventEmitter();
  collapsed: boolean = true;
  @Input() deals: any[];
  constructor(
      public authService: AuthService,
      notification: NotificationService,
      route: ActivatedRoute,
      menuService: MenusServices,
      private quoteService: StandardQuoteService
  ) {
    super(route, menuService, notification);
  }
  ngOnInit() {}

  viewDeal(indexDeal: any) {
    const element = document.getElementById(
      `deal${indexDeal}`
    ) as HTMLInputElement;
    element.classList.toggle('collapsed');
    this.collapsed = element.classList.contains('collapsed');
  }

  getFinancialProduct(financialProductType: FinancialProductEnum) {
    if (financialProductType == FinancialProductEnum.directCredit) {
      return 'Crédito directo';
    } else if (financialProductType == FinancialProductEnum.financialLeasing) {
      return 'Arrendamiento financiero';
    } else if (financialProductType == FinancialProductEnum.pureLease) {
      return 'Arrendamiento puro';
    } else {
      return '';
    }
  }

  showInsuranceVehicle(deal: any) {
    return Util.showInsuranceVehicle(deal);
  }

  toggleSelection(event: MouseEvent, index: number,id:number): void {
    const selectedCount = this.deals.filter(deal => deal.isSelected).length;

    if (!this.deals[index].financialSetup.active) {
      this.showNotificationError('El paquete financiero no está vigente');
      event.preventDefault();
      return;
    }

    if (this.deals[index].isSelected) {
      this.deals = this.deals.map((i)=>{return{...i,isSelected: i.id == id ? !this.deals[index].isSelected : i.isSelected}})
      this.isSelected.emit(id)
    } else {
      if (selectedCount >= 6) {
        this.showNotificationError('Ha excedido el número máximo de propuestas permitidas.');
        event.preventDefault();
      } else {
        this.deals = this.deals.map((i)=>{return{...i,isSelected: i.id == id ? !this.deals[index].isSelected : i.isSelected}})
        this.isSelected.emit(id)
      }
    }
  }

  validDealEditable(): boolean {
    const formData : any = this.quoteService.formData;
    let valid = true;
    if(formData.status === 'DRAFT'){
      valid = valid && formData.model.enabled;
    }
    return valid;
  }

}
