<section>
  <app-notifications variant="error"></app-notifications>
  <app-preloader *ngIf="this.isLoading"></app-preloader>
  <div id="register">
    <div class="wrapper">
      <h1>{{ "Register title" | translate }}</h1>

      <!-- Incluir dirección de mail del enlace financiero y nombre del Distribuidor-->
      <div class="box">
        <ng-container
          *ngIf="(dealerIdLinkDescription && profileIdLinkDescription); then showInvitation; else loaderInvitation">
        </ng-container>
        <ng-template #showInvitation>
          <p>
            <b>{{ emailLinkDescription }}</b> te han invitado a formar parte de
            <br />{{ dealerIdLinkDescription }} como {{ profileIdLinkDescription }}.
          </p>
        </ng-template>
        <ng-template #loaderInvitation>Cargando invitacion...</ng-template>
      </div>
      <p>{{ "Register text" | translate }}</p>
      <p class="note">
        {{ "Complete required data" | translate }}
        <span class="required">*</span>
      </p>

      <h2>{{ "Profile title" | translate }}</h2>

      <form [formGroup]="form" (ngSubmit)="onSubmit()" [ngClass]="{ 'disable-enter': disabledButton }">
        <div class="wrapperG2">
          <!-- Nombre -->
          <div class="controlWrapper">
            <label>{{ "Name" | translate }} <span class="required">*</span></label>
            <input [ngClass]="{
                invalid:
                  !form.controls['firstName'].valid &&
                  (submitted || !hideErrors)
              }" type="text" placeholder=" " formControlName="firstName" />

            <ng-container *ngIf="
                !form.controls['firstName'].valid &&
                (submitted || !hideErrors) &&
                form.controls['firstName'].hasError('required')
              "><span class="error">{{ "Name required" | translate }}.</span></ng-container>
          </div>

          <!-- Apellido paterno -->
          <div class="controlWrapper">
            <label>{{ "LastName" | translate }}
              <span class="required">*</span></label>
            <input [ngClass]="{
                invalid:
                  !form.controls['lastName'].valid && (submitted || !hideErrors)
              }" type="text" placeholder=" " formControlName="lastName" />

            <ng-container *ngIf="
                !form.controls['lastName'].valid &&
                (submitted || !hideErrors) &&
                form.controls['lastName'].hasError('required')
              "><span class="error">{{
                "LastName required" | translate
                }}</span></ng-container>
          </div>

          <!-- Apellido materno -->
          <div class="controlWrapper">
            <label>{{ "MothersLastName" | translate }}
              <span class="required">*</span></label>
            <input [ngClass]="{
                invalid:
                  !form.controls['mothersLastName'].valid &&
                  (submitted || !hideErrors)
              }" type="text" placeholder=" " formControlName="mothersLastName" />

            <ng-container *ngIf="
                !form.controls['mothersLastName'].valid &&
                (submitted || !hideErrors) &&
                form.controls['mothersLastName'].hasError('required')
              "><span class="error">{{
                "MothersLastName required" | translate
                }}</span></ng-container>
          </div>

          <!-- Fecha de nacimiento -->
          <div class="controlWrapper">
            <label>{{ "Date of Birth" | translate }}
              <span class="required">*</span></label>
            <app-date-picker [selectedDate]="form.get('dateOfBirth')?.value"
              (selectedDateChange)="form.get('dateOfBirth')?.setValue($event)" [hasError]="
                !form.get('dateOfBirth')?.valid && (submitted || !hideErrors)
              ">
            </app-date-picker>

            <ng-container *ngIf="form.get('dateOfBirth')?.errors">
              <span class="error" *ngIf="
                  form.get('dateOfBirth')?.hasError('required') &&
                  form.get('dateOfBirth')?.touched
                ">
                {{ "Date of Birth required" | translate }}
              </span>
              <span class="error" *ngIf="form.get('dateOfBirth')?.hasError('invalidDate')">
                {{ "maxAge" | translate }}
              </span>
              <span class="error" *ngIf="form.get('dateOfBirth')?.hasError('futureDate')">
                {{ "Date cannot be in the future" | translate }}
              </span>
            </ng-container>
          </div>

          <!-- Sexo -->
          <div class="controlWrapper">
            <label>{{ "Gender" | translate }} <span class="required">*</span></label>
            <app-select [options]="genders" [placeholder]="'Gender' | translate"
              (optionSelected)="form.controls['gender'].setValue($event)">
            </app-select>
            <ng-container *ngIf="
                !form.controls['gender'].valid &&
                (submitted || !hideErrors) &&
                form.controls['gender'].hasError('required')
              "><span class="error">{{
                "Gender required" | translate
                }}</span></ng-container>
          </div>

          <!-- Teléfono -->
          <div class="controlWrapper">
            <label>{{ "Phone" | translate }} <span class="required">*</span></label>

            <!-- Si el input no es válido agregar la clase "error" -->
            <input type="text" maxlength="10" placeholder=" " formControlName="telephoneNumber" [ngClass]="{
                invalid:
                  !form.controls['telephoneNumber'].valid &&
                  (submitted || !hideErrors)
              }" />

            <div *ngIf="
                form.get('telephoneNumber')?.errors &&
                form.get('telephoneNumber')?.dirty
              ">
              <span class="error" *ngIf="form.get('telephoneNumber')?.hasError('required')">{{ "Phone required" |
                translate }}</span>
              <span class="error" *ngIf="form.get('telephoneNumber')?.hasError('pattern')">{{ "Phone validation" |
                translate }}</span>
            </div>
          </div>

          <!-- Contraseña -->
          <div class="controlWrapper">
            <label>{{ "User input password" | translate
              }}<span class="required">*</span></label>
            <input type="password" placeholder=" " formControlName="password" (keydown.enter)="$event.preventDefault()"
              [ngClass]="{
                invalid:
                  !form.controls['password'].valid && (submitted || !hideErrors)
              }" />

            <ng-container *ngIf="
                !form.controls['password'].valid &&
                (submitted || !hideErrors) &&
                form.controls['password'].hasError('required')
              "><span class="error">{{
                "User input password required" | translate
                }}</span></ng-container>
            <ng-container *ngIf="
                form.controls['password'].errors && (submitted || !hideErrors)
              ">
              <span class="error">{{ "Password alert" | translate }}</span>
            </ng-container>
          </div>

          <!-- Repetir contraseña -->
          <div class="controlWrapper">
            <label>{{ "Repeat password" | translate }}
              <span class="required">*</span></label>
            <input type="password" placeholder=" " formControlName="confirmPassword"
              (keydown.enter)="$event.preventDefault()" [ngClass]="{
                invalid:
                  !form.controls['confirmPassword'].valid &&
                  (submitted || !hideErrors)
              }" />
          </div>
        </div>

        <div class="passwordRules">
          <p>{{ "Password rules" | translate }}</p>
          <ul>
            <li>
              <span class="icon" [ngClass]="{
                  confirmation: passwordValidation['uppercase'],
                  invalid: !passwordValidation['uppercase']
                }">{{ passwordValidation["uppercase"] ? "" : "" }}</span>{{ "Uppercase rule" | translate }}
            </li>
            <li>
              <span class="icon" [ngClass]="{
                  confirmation: passwordValidation['digit'],
                  invalid: !passwordValidation['digit']
                }">{{ passwordValidation["digit"] ? "" : "" }} </span>{{ "Digit rule" | translate }}
            </li>
            <li>
              <span class="icon" [ngClass]="{
                  confirmation: passwordValidation['length'],
                  invalid: !passwordValidation['length']
                }">{{ passwordValidation["length"] ? "" : "" }}</span>{{ "Character rule" | translate }}
            </li>
            <li>
              <span class="icon" [ngClass]="{
                  confirmation: passwordValidation['validCharacters'],
                  invalid: !passwordValidation['validCharacters']
                }">{{ passwordValidation["validCharacters"] ? "" : "" }}</span>{{ "Character valid" | translate }}
            </li>
            <li>
              <span class="icon" [ngClass]="{
                  confirmation: passwordValidation['lowercase'],
                  invalid: !passwordValidation['lowercase']
                }">{{ passwordValidation["lowercase"] ? "" : "" }}</span>{{ "Lowercase" | translate }}
            </li>
            <li>
              <span class="icon" [ngClass]="{
                  confirmation: passwordValidation['special'],
                  invalid: !passwordValidation['special']
                }">{{ passwordValidation["special"] ? "" : "" }}</span>{{ "Character special" | translate }}
            </li>

            <li *ngIf="form.controls['confirmPassword'].errors?.['mustMatch']">
              <span class="icon invalid"></span>{{ "Passwords not agree" | translate }}
            </li>
            <li *ngIf="!form.controls['confirmPassword'].errors?.['mustMatch']">
              <span class="icon confirmation"></span>{{ "Passwords agree" | translate }}
            </li>
          </ul>
        </div>
      </form>

      <div class="approval">
        <label class="checkboxWrapper" for="agree">
          <input type="checkbox" id="agree" [(ngModel)]="this.agreeChecked" required />

          <span class="checkmark"></span>
          <span class="description">{{ "agree" | translate
            }}<a href="info/tyc"> {{ "TyC" | translate }}</a>
            {{ "accept" | translate }}
            <a href="/info/privacy-statment">{{
              "Personal declaration" | translate
              }}</a>
            <span style="color: red">*</span></span>
        </label>
      </div>
      <div class="sendWrapper">
        <button #sendButton (click)="onSubmit()" [disabled]="disabledButton || !agreeChecked">
          {{ "Send" | translate }}
        </button>
      </div>
    </div>
  </div>
</section>
