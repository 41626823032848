<div class="controlWrapper">
  @if(label(); as labelText){
  <label>{{ labelText | translate }}<span class="required">*</span></label>
  } @if(!fileName() || !isValidSelected()){
  <!-- Si NO tiene un archivo adjunto -->
  <label
    #dropZone
    [for]="id()"
    (dragenter)="onDragEnter($event)"
    (dragleave)="onDragLeave($event)"
    (dragover)="onDragOver($event)"
    (drop)="onDrop($event)"
    [class.dragover]="isDragging()"
  >
    <span class="uploadArea">
      @if(!isDragging()){
      <img src="/assets/images/icons/upload.svg" alt="Cargar archivo" />
      <p>{{ uploadTitle() }}</p>
      }@else {
      <img src="/assets/images/icons/upload.svg" alt="Cargar archivo" />
      <p>Suelte el archivo aquí</p>
      }
      <span class="note">{{ uploadSubTitle() }}</span>
    </span>
    <input
      #inputFile
      type="file"
      name="photo"
      [id]="id()"
      (change)="onFileSelected($event)"
    />
  </label>
  } @else {
  <!-- Si tiene un archivo adjunto -->
  <div class="fileAttached">
    @if(preview(); as previewImg){
    <picture>
      <img [src]="previewImg" alt="Imagen seleccionada o existente" />
    </picture>
    }
    <div class="nameAndActions">
      <div class="name">
        <img src="/assets/images/icons/attach.svg" alt="Adjunto" />
        <span>
          {{ fileName() }}
        </span>
      </div>
      <a
        class="delete"
        tp="Eliminar Circular"
        role="button"
        (click)="onRemoveFile()"
        >Eliminar</a
      >
    </div>
  </div>
  }
</div>
