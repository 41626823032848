import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MenusServices} from '@app/services/menus.service';
import {BaseComponent} from '@app/components/base/base.component';
import {environment} from '@env/environment.local';
import {queryParamsQuote} from '../model/standard-quote.model';
import {statusType} from '../../open-quote/model/open-quote.model';
import {AuthService} from '@app/common/auth.service';
import {NotificationService} from '@app/services/notifications.service';
import {StandardQuoteService} from '../standard-quote-service.service';
import {ModalService} from '@app/components/modal/modal.service';
import {MessagesUtil} from "@app/core/model/Message";

@Component({
  selector: 'app-list-quote-standard',
  templateUrl: './list-quote-standard.component.html',
  styleUrls: ['./list-quote-standard.component.css'],
})
export class ListQuoteStandardComponent
  extends BaseComponent
  implements OnInit {
  @ViewChild('templateConfirmation') template!: TemplateRef<any>;
  @ViewChild('templateDuplicateConfirmation') templateDuplicate!: TemplateRef<any>;
  isLoading: boolean = false;
  hasData: boolean = false;
  isMobile: boolean = false;
  statusQuote: string | null = null;

  data: any[] = [];

  total: number = 0;

  pageSize: number = environment.pageSize;
  pageIndex: number = 1;

  params: queryParamsQuote = {
    filter: {
      dealerId: null,
      userType: null,
      page: this.pageIndex,
      pageSize: this.pageSize,
      me: false,
      customerName: null,
      quoteName: null,
      status: null,
      from: null,
      to: null,
    },
  };

  status: any = {
    data: [
      // {label:'Aprobado',value: statusType.approved},
      {label: 'Borrador', value: statusType.draft},
      {label: 'Completo', value: statusType.completed},
      {label: 'En progreso', value: statusType.inProgress},
      // {label:'En revisión',value: statusType.InReview},
    ],
    placeholder: 'Buscar por estado',
    filterType: 'status',
  };

  searchNameQuote: any = {placeholder: 'Buscar cotizacion', value: ''};
  searchNameCustomer: any = {placeholder: 'Buscar cliente', value: ''};

  userId: string;
  dealerId: number;
  user: string
  reset: boolean = false;
  idQuote: number;

  title: string = 'Eliminar cotización';
  subtitle: string = '';
  btn1: string = 'Cancelar';
  btn2: string = 'Eliminar';

  constructor(
    route: ActivatedRoute,
    public routerNavigate: Router,
    menu: MenusServices,
    notification: NotificationService,
    public quoteService: StandardQuoteService,
    private authService: AuthService,
    private _notification: NotificationService,
    private modalService: ModalService
  ) {
    super(route, menu, notification);
  }

  ngOnInit() {
    this.sendDataToMenu();
    this.isMobile = this.mobile();
    this.userId = this.authService.currentUserIds;
    this.user = this.authService.getCurrentUserType;
    this.dealerId = this.authService.getCurrentDealerId
    this.authService.getCurrentUserType;
    this.params.filter.me = true;
    this.getQuotes();
    this.quoteService.formData = null;
  }

  getQuotes() {
    this.isLoading = true;
    this.quoteService.getQuotes({
      ...this.params.filter,
      dealerId: this.dealerId,
      userType: this.user[0],
    }).subscribe({
      next: (response) => {
        this.data = response.data.filter((item: { dealerId: number; creationUser: string; }) =>
          item.dealerId === this.dealerId || item.creationUser === this.userId
        );

        this.total = this.data.length;
        this.pageIndex = response.page - 1;
        this.hasData = this.data.length > 0;
        this.isLoading = false;
        this.data.forEach(quote => {
          quote.invalidFor = quote.model.enabled === false && quote.status == 'DRAFT' ? 'Unidad ya no disponible' : null;
        })

      },
      error: (error) => {
        this.isLoading = false;
      },
    });
  }

  handlePage(e: any) {
    this.params.filter.page = e.pageIndex + 1;
    this.params.filter.pageSize = e.pageSize;
    this.getQuotes();
  }

  deleteQuoteById(): void {
    this.closeModal();
    this.isLoading = true;
    this.quoteService.deleteQuote(this.idQuote).subscribe({
      next: (response) => {
        this.showNotificationSuccess(
          'La cotización ha sido eliminada con éxito.'
        );
        this.isLoading = false;
        this.getQuotes();
      },
      error: (error) => {
        this.showNotificationError(
          'Ha ocurrido un error, la cotización no se pudo eliminar.'
        );
        this.isLoading = false;
      },
    });
  }


  duplicateQuoteById(): void {
    this.closeModal();
    this.isLoading = true;
    this.quoteService.duplicateStandardQuoteById(this.idQuote).subscribe({
      next: (response) => {
        if (response.status) {
          this.showNotificationSuccess(
            'La cotización ha sido duplicada con éxito.'
          );
          this.isLoading = false;
          this.routerNavigate.navigate(['/standard-quote/edit-quote/' + response.data.id])
        } else if (response.errors && response.errors.length > 0) {
          const key = response.errors[0];
          const message = MessagesUtil[key] as string || 'No se puede duplicar la cotización.';
          this.showNotificationError(message);
          this.isLoading = false;
          this.getQuotes();
        } else{
          this.showNotificationError('No se puede duplicar la cotización.');
          this.isLoading = false;
          this.getQuotes();
        }
      },
      error: (error) => {
        this.showNotificationError(
          'Ha ocurrido un error, la cotización no se pudo duplicar.'
        );
        this.isLoading = false;
      },
    });
  }

  closeModal() {
    this.modalService.closeModal();
  }


  getStatus(status: statusType) {
    if (status == statusType.InReview) {
      return 'En revisión';
    } else if (status == statusType.draft) {
      return 'Borrador';
    } else if (status == statusType.inProgress) {
      return 'En progreso';
    } else if (status == statusType.approved) {
      return 'Aprobado';
    } else if (status == statusType.completed) {
      return 'Completo';
    } else if (status == statusType.saved) {
      return 'Guardado';
    }
    return;
  }

  filter(value: any, typeSelect: string) {
    switch (typeSelect) {
      case 'status':
        this.params.filter.status = value;
        break;
      case 'quoteName':
        this.params.filter.quoteName = value == '' ? null : value;
        break;
      case 'customerName':
        this.params.filter.customerName = value == '' ? null : value;
        break;
      case 'me':
        this.params.filter.me = value;
        this.search();
        break;
      case 'from':
        this.params.filter.from = value;
        break;
      case 'to':
        this.params.filter.to = value;
        break;
    }
  }

  search() {
    this.params.filter.page = 1;
    this.getQuotes();
  }

  resetFilters() {
    this.params.filter = {
      dealerId: null,
      userType: null,
      page: 1,
      pageSize: this.pageSize,
      me: false,
      customerName: null,
      quoteName: null,
      status: null,
      from: null,
      to: null,
    };
    this.reset = true;
    this.getQuotes();
  }

  openModalConfirmation(quote: any) {
    this.btn2 = 'Eliminar';
    this.title = 'Eliminar cotización';
    this.idQuote = quote.id;
    this.subtitle = `¿Confirma la eliminación de la cotización "${quote.quoteName}"?`;

    this.modalService.component = this.template;
    this.modalService.openModal();
  }

  openModalToDuplicateStandardQuote(quote: any) {
    this.btn2 = 'Duplicar';
    this.title = 'Duplicar cotización';
    this.idQuote = quote.id;
    this.subtitle = `¿Confirma el duplicado de la cotización "${quote.quoteName}"?`;
    this.modalService.component = this.templateDuplicate;
    this.modalService.openModal();
  }


  notificationTest() {
    this._notification.clearMessages();
    this._notification.showMessage(
      ['Esta es una prueba de notificación'],
      'error'
    );
  }

  notificationTestSuccess() {
    this._notification.clearMessages();
    this._notification.showMessage(
      ['Esta es una prueba de notificación'],
      'success'
    );
  }

  showCustomerName(customer: any) {
    if (!customer) return '';
    return ((customer.firstName ?? '') + ' ' + (customer.secondName ?? '') + ' ' + (customer.mothersLastName ?? '') + ' ' + (customer.lastName ?? ''));
  }
}
