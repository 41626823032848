import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../../services/api.base.service';
import { environment } from '@env/environment.local';
import { DealerLinkInvitationStatus } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class RegisterService {
  constructor(public api: ApiService) {}

  public verifyDealerLinkInvitation(inviteToken: string): Observable<any> {
    return this.api.get(`${environment.API_DEALEARS_SERVICE}/dealers/check/${inviteToken}/link-invitation`);
  }

  public updateDealerLinkInvitationStatus(inviteToken: string, status: DealerLinkInvitationStatus): Observable<any> {
    return this.api.put(`${environment.API_DEALEARS_SERVICE}/dealers/check/${inviteToken}/link-invitation/${status}`);
  }

  public registerByDealerInvitation(model: any) {
    return this.api.post(`${environment.API_USER_SERVICE}/users/signup/byDealerInvitation`, model);
  }

}
