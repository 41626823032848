import {
  ChangeDetectionStrategy,
  Component,
  effect,
  ElementRef,
  input,
  output,
  signal,
  viewChild,
} from '@angular/core';
@Component({
  selector: 'app-input-file-select',
  templateUrl: './input-file-select.component.html',
  styleUrl: './input-file-select.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputFileSelectComponent {
  // Inputs
  readonly label = input<string>('');
  readonly uploadTitle = input<string>('Hacer clic o arrastrar archivo aquí');
  readonly uploadSubTitle = input<string>('Formato PDF, JPG o PNG.');
  readonly id = input<string>('fileUpload');
  readonly isValidSelected = input<boolean>(true);
  readonly defaultFile = input<any>();

  // Outputs
  readonly onSelected = output();
  readonly onRemoved = output();

  // Signals
  readonly fileName = signal<string>('');
  readonly isDragging = signal(false);
  readonly preview = signal<ArrayBuffer | string | null>(null);

  // ViewChild
  readonly inputFile = viewChild<ElementRef<HTMLInputElement>>('inputFile');
  readonly dropZone = viewChild<ElementRef<HTMLLabelElement>>('dropZone');

  // Atributos
  private fileReader: FileReader | null = null;

  constructor() {
    effect(
      () => {
        const defaultValue = this.defaultFile();
        if (defaultValue && this.isValidSelected()) {
          this.fileName.set(defaultValue.originalName);
          this.preview.set(defaultValue.urlSource);
        } else if (!this.isValidSelected()) {
          this.resetComponent();
        }
      },
      { allowSignalWrites: true }
    );
  }

  private resetComponent(): void {
    this.fileName.set('');
    this.preview.set(null);
    this.resetDragState();

    if (this.fileReader) {
      this.fileReader.abort();
      this.fileReader = null;
    }

    const el = this.inputFile()?.nativeElement;
    if (el) {
      el.value = '';
    }
  }

  private handleFileSelection(file: File): void {
    this.fileName.set(file?.name);

    if (file.type.startsWith('image/')) {
      this.fileReader = new FileReader();
      this.fileReader.onload = () => {
        this.preview.set(this.fileReader?.result as string);
      };
      this.fileReader.readAsDataURL(file);
    }
  }

  private isValidDraggedFile(event: DragEvent): boolean {
    const items = event.dataTransfer?.items;
    if (!items?.length) return false;

    const types = event.dataTransfer?.types;
    return types?.includes('Files') || false;
  }

  onRemoveFile() {
    this.resetComponent();
    this.onRemoved.emit();
  }

  onFileSelected(event: any) {
    const input = event.target as HTMLInputElement;
    const file = input.files?.[0];
    if (file) {
      this.handleFileSelection(file);
      this.onSelected.emit(event);
    }
  }

  onDragEnter(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (this.isValidDraggedFile(event)) {
      this.isDragging.set(true);
    }
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    const rect = (event.currentTarget as HTMLElement).getBoundingClientRect();
    const { clientX, clientY } = event;

    const isOutside =
      clientX <= rect.left ||
      clientX >= rect.right ||
      clientY <= rect.top ||
      clientY >= rect.bottom;

    if (isOutside) {
      this.resetDragState();
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.resetDragState();

    const files = event.dataTransfer?.files;

    if (files?.length) {
      const file = files[0];
      // Crear un nuevo objeto DataTransfer
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(file);

      // Actualizar el input file con el archivo arrastrado
      const input = this.inputFile()?.nativeElement;
      if (input) {
        input.files = dataTransfer.files;
        // Disparar el evento change manualmente
        const event = new Event('change', { bubbles: true });
        input.dispatchEvent(event);
      }
    }
  }

  private resetDragState(): void {
    this.isDragging.set(false);
  }
}
