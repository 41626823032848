<div class="extract collapsed" [id]="'deal' + indexDeal">
  <!--!isInsuranceEdition  -->
  <div class="title">
    <div class="name">
      @if (validDealEditable()) {
        <label class="checkboxWrapper" [for]="indexDeal + 'deal'">
          <input type="checkbox" [id]="indexDeal + 'deal'" [checked]="deal.isSelected" [disabled]="
              !deal.payments ||
              !deal.payments.length ||
              creationUser != authService.currentUserIds ||
              deal.financialSetup.active == false ||
              status === 'SAVED'
            " (click)="toggleSelection($event, indexDeal, deal.id)" />
          <span class="checkmark"></span>
          <span class="description">{{ getFinancialProduct(deal?.financialSetup?.financialProduct) }}
            {{ deal?.financialCondition?.term + " meses" }}</span>
        </label>
      }@else {
        <label class="checkboxWrapper" [for]="indexDeal + 'deal'">
          <span class="description">{{ getFinancialProduct(deal?.financialSetup?.financialProduct) }}
            {{ deal?.financialCondition?.term + " meses" }}</span>
        </label>
      }
      @if (status !== 'SAVED' && isValidForm && validDealEditable() && validBranch === false) {
      <div class="actions" *ngIf=" creationUser == null || creationUser == authService.currentUserIds ">
        <a (click)="edit.emit(deal.id)" class="edit" tp="Editar propuesta">Editar</a>

        <a (click)="duplicate.emit(deal.id)" class="duplicate" tp="Duplicar propuesta">Duplicar</a>

        <a (click)="delete.emit(deal.id)" class="delete" tp="Eliminar propuesta">Eliminar</a>
      </div>
      }
    </div>
  </div>
  <div class="package">
    <p>{{deal.financialSetup.financialSetupName}}</p>
    <!-- Incluir span a cotinuación si el Paquete Financiero no está vigente-->
    @if (!deal.financialSetup.active){
    <span class="tag rechazado">Paquete Financiero no vigente</span>
    }
  </div>
  <div class="resume">
    <a class="dragControl" tp="Arrastrar para cambiar el orden" helipopperPlacement="right">Arrastrar para cambiar el orden</a>
    <div class="wrapper">
      <div class="row">
        <div>
          <span>Producto Financiero</span>
          <span>{{
            getFinancialProduct(deal.financialSetup.financialProduct)
            }}</span>
        </div>
        <div>
          <span>Plazo</span>
          <span>{{ deal?.financialCondition?.term + " meses" }}</span>
        </div>
        <div>
          <span>Precio unitario con IVA</span>
          <span>{{ model.price | currency : "" : "" }}</span>
        </div>
        <div>
          <span>Tasa al cliente</span>
          <span>{{
            deal?.financialCondition?.nominalRate.toFixed(2) + " %"
            }}</span>
        </div>
        <div>
          @if(deal.financialSetup.financialProduct == "PURE_LEASING" ) {
          <span>Valor Residual</span>
          <span>
            {{ deal.result.residualValue | currency : "" : "" }}
          </span>
          } @else {
          <span>Enganche</span>
          <span>
            {{ deal?.result?.downpaymentAmount | currency : "" : "" }}
          </span>
          }
        </div>
        <div>
          <span>Comisión por apertura</span>
          <span>{{ deal?.result?.openingfeeAmount | currency : "" : "" }}</span>
        </div>
      </div>
      <div class="row">
        <div>
          <span>Seguro del vehículo</span>
          <span> {{ showInsuranceVehicle(deal) | currency : "" : "" }}</span>
        </div>
        <div>
          <span>Seguro de vida</span>
          <span> {{ deal?.result?.insuranceAmount | currency : "" : "" }}</span>
        </div>
        <div>
          <span>Gastos de ratificación</span>
          <span>{{
            deal?.result?.confirmationExpensesAmount | currency : "" : ""
            }}</span>
        </div>
        <div>
          <span>Pago inicial</span>
          <span>{{ deal?.result?.initialPayment | currency : "" : "" }}</span>
        </div>
        <div>
          <span>Monto a financiar</span>
          <span>{{ deal?.result?.financialAmount | currency : "" : "" }}</span>
        </div>
        <div>
          <span>Pago mensual</span>
          <span>{{ deal?.result?.monthlyPayment | currency : "" : "" }}</span>
        </div>
      </div>
    </div>
    <a class="accordionControl" (click)="viewDeal(indexDeal)"
      tp="{{ collapsed ? 'Ver detalle' : 'Ocultar detalle' }}"></a>
  </div>
  <div class="accordionWrapper">
    <div>
      <app-view-deal-standard-quote
        [payments]="deal.payments"
        [specialPayments]="deal.specialPayments"
        [grace]="deal?.grace"
        [idDeal]="deal.id"
        [id]="idQuote"
        [financialProduct]="deal.financialProduct"
      />
    </div>
  </div>
</div>
