export class QuoteOpenDto {
  nameCompany: string;
  nameQuote: string;
  daysOfValidity: number;
  customer: Customer;
  proposals: ProposalsDto[];
}

export class Customer {
  customerType: string;
  dfsRating: number;
  personType: string;
  taxFree: boolean;
}

export class ProposalsDto {
  currencyType: string;
  quoteUsd: number;
  tax: number;
  rating: number;
  customerRate: number;
  financialProductType: number;
  term: number;
  productType: string;
  feature: string;
  classification: string;
  useTime: number;
  unitPriceWithoutTax: number;
  unitPriceWithTax: number;
  totalPriceWithoutTax: number;
  totalPriceWithTax: number;
  downPaymentTaxType: string;
  downPaymentWithTax: number;
  downPaymentWithoutTax: number;
  downPaymentTax: number;
  downPayment: number;
  financialAmount: number;
  openingFeeType: string;
  openingFee: number;
  openingFeePercentage: number;
  warrantyDeposit: string;
  warrantyDepositMonth: number;
  commissionType: string;
  commissionPercentag: number;
  commissionTotal: number;
  subsidyType: string;
  subsidyPercentage: number;
  subsidyTotal: number;
  purchaseOptionType: string;
  purchasePercentage: number;
  purchaseTotal: number;
  residualValueType: string;
  residualValuePercentage: number;
  residualValueTotal: number;
  paymentType: string;
  paymentFormationType: string;
  gracePeriod: number;
  confirmationExpensesAmount: number;
  lifeInsuranceFactor: number;
  productInsuranceAmount: number;
  payments: PaymentsDto[];
}

export class PaymentsDto {
  period: number;
  paymentDate: string;
  capitalBalance: number;
  capitalPayment: number;
  interest: number;
  interestTax: number;
  total: number;
  paymentType: string;
}

export class queryParamsQuote {
  filter: {
    page: number;
    pageSize: number;
    me: boolean;
    customerName: any;
    quoteName: any;
    status: any;
    to: any;
    from: any;
  };
}

export enum productTypeEnum {
  accesorios = 'ACCESSORIES',
  vanes = 'VANES',
  autobuses = 'BUSES',
  camiones = 'TRUCKS',
  tractos = 'TRACTS',
  nonDamiler = 'NON_DAIMLER',
}

export enum modelTypeEnum {
  trailers = 'TRAILERS',
  autobuses = 'BUSES',
  accesorios = 'ACCESSORIES',
  camiones = 'TRUCKS',
  carga = 'LOAD',
  tractos = 'TRACTS',
  pasaje = 'PASSAGE',
  nonDaimler = 'NON_DAIMLER',
  vanes = 'VANES',
}

export const modelTypeDescriptions = {
  [modelTypeEnum.accesorios]: 'Accesorios',
  [modelTypeEnum.vanes]: 'Vanes',
  [modelTypeEnum.autobuses]: 'Autobuses',
  [modelTypeEnum.camiones]: 'Camiones',
  [modelTypeEnum.tractos]: 'Tractos',
  [modelTypeEnum.nonDaimler]: 'Non Daimler',
  [modelTypeEnum.trailers]: 'Trailers',
  [modelTypeEnum.pasaje]: 'Pasaje',
  [modelTypeEnum.carga]: 'Carga',
};

export enum TaxType {
  invoiceWithTax = 'INVOICE_VALUE_TAX',
  invoiceWithoutTax = 'INVOICE_VALUE_WITHOUT_TAX',
  amountToFinance = 'AMOUNT_TO_FINANCE',
  fixedAmount = 'FIXED_AMOUNT',
  numberOfRents = 'NUMBER_OF_RENTS',
  NOT_TAXED = 'NOT_TAXED',
  UNIT_VALUE = 'UNIT_VALUE',
  TOTAL_VALUE = 'TOTAL_VALUE',
}

export const taxTypes = [
  { value: TaxType.invoiceWithTax, display: '% Valor con IVA' },
  { value: TaxType.invoiceWithoutTax, display: '% Valor sin IVA' },
  { value: TaxType.fixedAmount, display: 'Monto fijo' },
  { value: TaxType.amountToFinance, display: ' % Monto a financiar' },
  { value: TaxType.numberOfRents, display: 'Número de rentas' },
  { value: TaxType.NOT_TAXED, display: 'No aplica' },
];

export const additionalCollateralTaxType = {
  UNIT_VALUE: 'UNIT_VALUE',
  TOTAL_VALUE: 'TOTAL_VALUE',
};

export const additionalCollateralTaxTypes = [
  { value: additionalCollateralTaxType.UNIT_VALUE, display: '% Valor unidad' },
  { value: additionalCollateralTaxType.TOTAL_VALUE, display: '% Valor total' },
  { value: TaxType.NOT_TAXED, display: 'No aplica' },
];

export const residualValueTypes = [
  { value: TaxType.invoiceWithTax, display: '% Valor con IVA' },
  { value: TaxType.invoiceWithoutTax, display: '% Valor sin IVA' },
  { value: TaxType.fixedAmount, display: 'Monto fijo' },
  { value: TaxType.amountToFinance, display: ' % Monto a financiar' },
];

export const subsidyTaxTypes = [
  { value: TaxType.invoiceWithTax, display: '% Valor con IVA' },
  { value: TaxType.invoiceWithoutTax, display: '% Valor sin IVA' },
  { value: TaxType.fixedAmount, display: 'Monto fijo' },
  { value: TaxType.amountToFinance, display: ' % Monto a financiar' },
  { value: TaxType.NOT_TAXED, display: 'No aplica' },
];

export const downPaymentTaxType = [
  { value: TaxType.invoiceWithTax, display: '% Valor con IVA' },
  { value: TaxType.invoiceWithoutTax, display: '% Valor sin IVA' },
];

export const purchaseOptionTypes = [
  { value: TaxType.invoiceWithTax, display: '% Valor con IVA' },
  { value: TaxType.invoiceWithoutTax, display: '% Valor sin IVA' },
  { value: TaxType.amountToFinance, display: ' % Monto a financiar' },
];

export const companyTypes = [
  { value: 'DTFS', display: 'DTFS' },
  { value: 'SOFOM', display: 'SOFOM' },
];
export const warrantyDepositType = [
  { value: TaxType.numberOfRents, display: 'Mensualidades' },
];

export const openingFeeType = [
  { value: TaxType.amountToFinance, display: ' % Monto a financiar' },
];

export const currencies = [
  { type: 'MXN', value: 'MXN' },
  { type: 'USD', value: 'USD' },
];

export enum AdditionalWarrantyType {
  unitValue = 'UNIT_VALUE',
  totalValue = 'TOTAL_VALUE',
}

export enum statusType {
  inProgress = 'IN_PROGRESS',
  approved = 'APPROVED',
  completed = 'COMPLETED',
  InReview = 'IN_REVIEW',
  saved = 'SAVED',
  draft = 'DRAFT',
  deprecated = 'DEPRECATED'
}

export enum paymentType {
  regularPayment = 'REGULAR_PAYMENT',
  capitalAnd_Interest = 'CAPITAL_AND_INTEREST',
  capital = 'CAPITAL',
  interest = 'INTEREST',
  prepaid = 'PREPAID',
  expiration = 'EXPIRATION',
}

export enum FinancialProductEnum {
  directCredit = 'DIRECT_CREDIT',
  financialLeasing = 'FINANCIAL_LEASING',
  pureLease = 'PURE_LEASING',
  directCreditGlobe = 'DIRECT_CREDIT_GLOBE',
}

export enum SegmentOpexType {
  freightLinerRetailBusiness = 'FREIGHT_LINER_RETAIL_BUSINESS',
  freightLinerSmallFleets = 'FREIGHT_LINER_SMALL_FLEETS',
  freightLinerMediumFleets = 'FREIGHT_LINER_MEDIUM_FLEETS',
  freightLinerLargeFleets = 'FREIGHT_LINER_LARGE_FLEETS',
  freightLinerExtraFleets = 'FREIGHT_LINER_EXTRA_LARGE_FLEETS',
}

export class DiroResponseDto {
  customerRateIn: string;
  customerRateInNom: string;
  idCsSubsidiesFeesUpfront: string;
  dealRateIrr: string;
  dealRateIrrNom: string;
  costOfDebt100MatchedFunded: string;
  grossInterestMargin: string;
  grossInterestMarginNom: string;
  capitalAdvantage: string;
  netInterestMargin: string;
  netInterestMarginNom: string;
  standardCostOfCreditRisk: string;
  opex: string;
  feesSubsPeriodicRvEndLease: string;
  netEbitMargin: string;
  netEbitMarginNom: string;
  economicCapital: string;
  plfGuar: string;
  pls: string;
  dfsMasterScale: string;
  effectiveMaturity: string;
  customerInstallment: string;
  calculationAsOf: string;
  interestAsOf: string;
  spreadsAsOf: string;
  createdOn: string;
  quote: string;
  rorac: string;
  idQuote: string;
  idDeal: string;
  urlQuote: string;
  urlDeal: string;
  constructor(data: any) {
    this.customerRateIn = data.customerRateIn;
    this.customerRateInNom = data.customerRateInNom;
    this.idCsSubsidiesFeesUpfront = data.idCsSubsidiesFeesUpfront;
    this.dealRateIrr = data.dealRateIrr;
    this.dealRateIrrNom = data.dealRateIrrNom;
    this.costOfDebt100MatchedFunded = data.costOfDebt100MatchedFunded;
    this.grossInterestMargin = data.grossInterestMargin;
    this.grossInterestMarginNom = data.grossInterestMarginNom;
    this.capitalAdvantage = data.capitalAdvantage;
    this.netInterestMargin = data.netInterestMargin;
    this.netInterestMarginNom = data.netInterestMarginNom;
    this.standardCostOfCreditRisk = data.standardCostOfCreditRisk;
    this.opex = data.opex;
    this.feesSubsPeriodicRvEndLease = data.feesSubsPeriodicRvEndLease;
    this.netEbitMargin = data.netEbitMargin;
    this.netEbitMarginNom = data.netEbitMarginNom;
    this.economicCapital = data.economicCapital;
    this.plfGuar = data.plfGuar;
    this.pls = data.pls;
    this.dfsMasterScale = data.dfsMasterScale;
    this.effectiveMaturity = data.effectiveMaturity;
    this.customerInstallment = this.convertirCadenaANumero(
      data.customerInstallment
    );
    this.calculationAsOf = data.calculationAsOf;
    this.interestAsOf = data.interestAsOf;
    this.spreadsAsOf = data.spreadsAsOf;
    this.createdOn = data.createdOn;
    this.quote = data.quote;
    this.rorac = data.rorac;
    this.idQuote = data.idQuote;
    this.idDeal = data.idDeal;
    this.urlQuote = data.urlQuote;
    this.urlDeal = data.urlDeal;
  }
  convertirCadenaANumero(cadena: string): string {
    if (cadena) return cadena.replace(/[$,]/g, '').trim();
    return cadena;
  }
}

export class DiroStatus {
  fase: string;
  idPetition: number;
  pcGeneral: string;
  data: any;
}

export const financialProductsList = [
  { name: 'Crédito directo', value: FinancialProductEnum.directCredit },
  {
    name: 'Arrendamiento financiero',
    value: FinancialProductEnum.financialLeasing,
  },
  { name: 'Arrendamiento puro', value: FinancialProductEnum.pureLease },
  { name: 'Crédito directo Balloon', value: FinancialProductEnum.directCreditGlobe },
];
