import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { authInterceptorProviders } from './_helpers/auth.interceptor';
import { CommonModule, registerLocaleData } from '@angular/common';
import { LoginModule } from './pages/login/login.module';
import { RegisterModule } from './pages/register/register.module';
import { RegisterFinancialLinkModule } from './pages/register-financial-link/register-financial-link.module';
import { AppCommonModule } from '@components/app.common.module';
import { CommonServicesModule } from './services/services.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { InternalNotificationsModule } from './pages/internal-notifications/internal-notifications.module';
import { AuthReverseGuard } from './common/authReverse.guard';
import { HttpErrorInterceptor } from './common/common.http.error.interceptor';
import { MailshotModule } from './pages/mailshot/mailshot.module';
import { QuoteModule } from './pages/quote/quote.module';
import es from '@angular/common/locales/es';
import {
  provideTippyConfig,
  tooltipVariation,
  popperVariation
} from '@ngneat/helipopper';
import { ModalComponent } from '@components/modal/modal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

registerLocaleData(es);
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent, HomeComponent, ModalComponent],
  imports: [
    RouterModule,
    LoginModule,
    RegisterModule,
    RegisterFinancialLinkModule,
    InternalNotificationsModule,
    CommonModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    AppCommonModule,
    CommonServicesModule,
    MailshotModule,
    QuoteModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http, './assets/i18n/', '.json');
        },
        deps: [HttpClient],
      },
    }),
    NgbModule,
  ],

  providers: [
    authInterceptorProviders,
    AuthReverseGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    provideTippyConfig({
      defaultVariation: 'tooltip',
      variations: {
        tooltip: tooltipVariation,
        popper: popperVariation,
      },
      duration:  [null, 0],
      delay: [0, 0]
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
