import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HeadersComponent } from './headers/headers.component';
import { NotificationsComponent } from './notifications/notifications.components';
import { RegisterConfirmationComponent } from './register-confirmation/register-confirmation.component';
import { MenuComponent } from './menu/menu.component';
import { FooterComponent } from './footer/footer.component';
import { ApiService } from '../services/api.base.service';
import { LabelDealerComponent } from './label-dealer/label-dealer.component';
import { PreloaderComponent } from './preloader/preloader.component';
import { TableComponent } from './table/table.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ButtonComponent } from './button/button.component';
import { RouterModule } from '@angular/router';
import { ImgHeaderComponent } from './img-header/img-header.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { GoBackComponent } from './go-back/go-back.component';
import { ModalSelectComponent } from './modal-select/modal-select.component';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { PermissionDirective } from '../directives/permission.directive';
import { AuthGuard } from '../common/auth.guard';
import { AuthReverseGuard } from '../common/authReverse.guard';
import { PaginatorComponent } from './paginator/paginator.component';
import { TranslateModule } from '@ngx-translate/core';
import { BaseComponent } from './base/base.component';
import { MailshotMobileComponent } from './mobile/mailshot-mobile/mailshot-mobile.component';
import { SelectComponent } from './select/select.component';
import { SelectSearchComponent } from './select-search/select-search.component';
import { InputSearchComponent } from './input-search/input-search.component';
import { FileSizePipe } from '../pipe/fileSize.pipe';
import { QuoteMobileComponent } from './mobile/quote-mobile/quote-mobile.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ModalConfirmationComponent } from './modal-confirmation/modal-confirmation.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InputComponent } from './input/input.component';
import { TemplateConfirmationComponent } from './template-confirmation/template-confirmation.component';
import { GraceSpecialPaymentsComponent } from './grace-special-payments/grace-special-payments.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import {
  popperVariation,
  provideTippyConfig,
  tooltipVariation,
  TippyDirective,
} from '@ngneat/helipopper';
import { TaggableMultiSelectComponent } from '@app/components/taggable-multi-select/taggable-multi-select.component';
import { InputFileSelectComponent } from '@app/components/input-file-select/input-file-select.component';

@NgModule({
  imports: [
    TippyDirective,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatPaginatorModule,
    MatInputModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatIconModule,
    RouterModule,
    MatDialogModule,
    TranslateModule,
    MatDatepickerModule,
    BsDatepickerModule.forRoot(),
    NgbPaginationModule,
  ],
  exports: [
    RouterModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    HeadersComponent,
    NotificationsComponent,
    RegisterConfirmationComponent,
    MenuComponent,
    FooterComponent,
    LabelDealerComponent,
    PreloaderComponent,
    TableComponent,
    ButtonComponent,
    ImgHeaderComponent,
    SearchBarComponent,
    GoBackComponent,
    PermissionDirective,
    PaginatorComponent,
    BaseComponent,
    MailshotMobileComponent,
    SelectComponent,
    SelectSearchComponent,
    InputSearchComponent,
    FileSizePipe,
    QuoteMobileComponent,
    DatePickerComponent,
    ModalConfirmationComponent,
    MatTooltipModule,
    InputComponent,
    TemplateConfirmationComponent,
    GraceSpecialPaymentsComponent,
    HeadersComponent,
    ImgHeaderComponent,
    NgbPaginationModule,
    InputFileSelectComponent,
    TaggableMultiSelectComponent,
  ],
  declarations: [
    ImgHeaderComponent,
    HeadersComponent,
    NotificationsComponent,
    RegisterConfirmationComponent,
    MenuComponent,
    FooterComponent,
    LabelDealerComponent,
    PreloaderComponent,
    TableComponent,
    ButtonComponent,
    SearchBarComponent,
    GoBackComponent,
    ModalSelectComponent,
    PermissionDirective,
    PaginatorComponent,
    BaseComponent,
    MailshotMobileComponent,
    SelectComponent,
    SelectSearchComponent,
    InputSearchComponent,
    FileSizePipe,
    QuoteMobileComponent,
    DatePickerComponent,
    ModalConfirmationComponent,
    InputComponent,
    TemplateConfirmationComponent,
    GraceSpecialPaymentsComponent,
    InputFileSelectComponent,
    TaggableMultiSelectComponent,
  ],
  providers: [
    ApiService,
    AuthGuard,
    AuthReverseGuard,
    provideTippyConfig({
      defaultVariation: 'tooltip',
      variations: {
        tooltip: tooltipVariation,
        popper: popperVariation,
      },
      duration: [null, 0],
      delay: [600, 0],
    }),
  ],
})
export class AppCommonModule {}
