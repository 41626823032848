<div class="tagsComponent">
  @if (label(); as labelText) {
  <label>{{ labelText | translate }}</label>
  }
  <div class="listWrapper">
    @if (selectedOptions().length > 0) {
    <ul>
      @for(option of selectedOptions(); track $index){
      <li>
        <div>
          <span>{{ getDisplayValue(option) }}</span>
          <button
            role="button"
            type="button"
            aria-label="Remover opcion"
            tabindex="-1"
            (click)="onRemove(option)"
          >
            <span role="img">Remover opcion</span>
          </button>
        </div>
      </li>
      }
    </ul>
    }

    <input
      #outComesInput
      type="text"
      [placeholder]="placeholder()"
      [attr.aria-expanded]="open()"
      [value]="searchQuery()"
      role="combobox"
      aria-autocomplete="list"
      aria-haspopup="listbox"
      autocomplete="off"
      (focus)="openDropdown()"
      (input)="onChangeQuery($event)"
      (keydown)="onKeyDown($event)"
    />
  </div>

  @defer (when open()) { @if(open()){
  <div
    #listBox
    class="autocomplete-list"
    role="listbox"
    [ngClass]="{ openUp: this.openUp(), 'hide': !isPositioned(), }"
  >
    <div
      class="autocomplete-option"
      role="option"
      aria-disabled="false"
      [attr.aria-selected]="hasSelected(getDefaultTodos())"
      (click)="onSelect(getDefaultTodos())"
    >
      Todos
    </div>
    @for(option of filteredOptions(); track getDisplayValue(option)){
    <div
      class="autocomplete-option"
      role="option"
      aria-disabled="false"
      [attr.aria-selected]="hasSelected(option)"
      (click)="onSelect(option)"
    >
      {{ getDisplayValue(option) }}
    </div>
    }
  </div>
  } }
</div>
