<app-img-header
  title="Cotizador estándar"
  alt="Daimler Truck"
  image="/assets/images/cotizador/intro.jpg"
  [contentTemplate]="template"
>
</app-img-header>
<app-preloader *ngIf="isLoading"></app-preloader>
<app-notifications></app-notifications>
<main class="main" id="">
  <div id="detail" class="white">
    <div class="pageWidth">
      <div class="actionsWrapper">
        <button
          class="tertiary"
          [routerLink]="['/quote/standard-quote/new-quote']"
        >
          <i class="icon"></i> Nueva cotización
        </button>
      </div>
      <!-- <button (click)="notificationTest()">Notificacion error</button>
      <button (click)="notificationTestSuccess()">Notificacion success</button> -->

      <div *ngIf="!hasData" class="noData">No hay información para mostrar</div>
      <div
        *ngIf="hasData"
        class="customTable listadoCotizacionesEstandar"
      >
        <div class="header">
          <div class="cell numero">Nº</div>
          <div class="cell nombre">Nombre de la cotización</div>
          <div class="cell cliente">Nombre del cliente</div>
          <div class="cell propuestas">Propuestas</div>
          <div class="cell total">Total</div>
          <div class="cell propietario">Propietario</div>
          <div class="cell edicion">Última edición</div>
          <div class="cell estado">Estado</div>
          <div class="cell acciones"></div>
        </div>

        <div *ngFor="let quote of data" class="row" [tp]="quote.invalidFor" [ngClass]="{'obsolete': !!(quote.invalidFor) }" >
          <div class="cell numero">
            {{ quote.id }}
          </div>
          <div class="cell nombre">
            <!-- Si contiene más de 28 caracteres mostrar tp -->
            <span
              *ngIf="quote.quoteName.length > 28; else shortQuoteName"
              tp="{{ quote.quoteName }}"
            >
              {{ quote.quoteName }}
            </span>
            <ng-template #shortQuoteName>{{ quote.quoteName }}</ng-template>
          </div>

          <div class="cell cliente">
            <!-- Si contiene más de 21 caracteres mostrar tp -->
            <span tp="{{ showCustomerName(quote.customer) }}">
              {{ showCustomerName(quote.customer) }}
            </span>
          </div>

          <div class="cell propuestas">
            {{ quote.dealAmount }}
          </div>
          <!-- Incluir valor total de la cotización -->
          <div class="cell total">{{ quote.faTotal | currency }}</div>
          <div class="cell propietario">
            <!-- Si contiene más de 23 caracteres mostrar tp -->
            <span
              *ngIf="quote.userName?.length > 23; else shortUserName"
              tp="{{ quote.userName }}"
            >
              {{ quote.userName }}
            </span>
            <ng-template #shortUserName>{{ quote.userName }}</ng-template>
          </div>
          <div class="cell edicion">
            {{ format(quote.updatedDate, true) }}
          </div>
          <div class="cell estado">
            {{ getStatus(quote.status) }}
          </div>
          <div class="cell acciones">
            @if (quote.status === 'SAVED' || userId != quote.creationUser) {
              <a [routerLink]="['/standard-quote/edit-quote/' + quote.id]" tp="Ver cotización" class="view" >Ver</a>
              <a tp="Duplicar cotización" (click)="openModalToDuplicateStandardQuote(quote)" class="duplicate">Duplicar</a>
            } @else {
              @if(quote.invalidFor) {
                <a [routerLink]="['/standard-quote/edit-quote/' + quote.id]" tp="Editar cotización" class="edit" >Editar</a>
              }@else {
                <a *ngIf="userId == quote.creationUser" [routerLink]="['/standard-quote/edit-quote/' + quote.id]" tp="Editar cotización" class="edit" >Editar</a>
                <a tp="Duplicar cotización" (click)="openModalToDuplicateStandardQuote(quote)" class="duplicate">Duplicar</a>
              }
              <a *ngIf="userId == quote.creationUser" tp="Eliminar cotización" class="delete" (click)="openModalConfirmation(quote)" >Eliminar</a>
            }
          </div>
        </div>
      </div>
      <div *ngIf="isMobile && hasData">
        <app-quote-mobile [listQuote]="data"></app-quote-mobile>
      </div>

      <div *ngIf="hasData">
        <app-paginator
          [total]="total"
          [pageSize]="pageSize"
          [pageIndex]="pageIndex"
          (changePage)="handlePage($event)"
        ></app-paginator>
      </div>

      <button
        class="tertiary"
        [routerLink]="['/quote/standard-quote/new-quote']"
      >
        <i class="icon"></i> Nueva cotización
      </button>
    </div>
  </div>
</main>
<ng-template #template>
  <div class="wrapperG3">
    <div>
      <label>Nombre de la cotización</label>
      <app-input
        [data]="searchNameQuote"
        (textSearch)="filter($event, 'quoteName')"
        [resetInput]="reset"
      ></app-input>
    </div>
    <div>
      <label>Nombre del cliente</label>
      <app-input
        [data]="searchNameCustomer"
        (textSearch)="filter($event, 'customerName')"
        [resetInput]="reset"
      ></app-input>
    </div>
  </div>
  <div class="wrapperG3" style="margin-top: 20px">
    <app-date-picker
      label="Desde"
      [(selectedDate)]="params.filter.from"
      (selectedDateChange)="filter($event, 'from')"
    ></app-date-picker>
    <app-date-picker
      label="Hasta"
      [(selectedDate)]="params.filter.to"
      (selectedDateChange)="filter($event, 'to')"
    ></app-date-picker>
    <div>
      <label class="checkboxWrapper myQuotation" for="myQuotation">
        <input
          type="checkbox"
          id="myQuotation"
          [(ngModel)]="params.filter.me"
          (ngModelChange)="filter($event, 'me')"
        />
        <span class="checkmark"></span>
        <span class="description">Mis cotizaciones</span>
      </label>
    </div>
  </div>
  <div class="filtersActions">
    <button class="primary" (click)="search()">Buscar</button>
    <button class="secondary" (click)="resetFilters()">Limpiar filtros</button>
  </div>
</ng-template>
<ng-template #templateConfirmation>
  <app-template-confirmation
    [title]="title"
    [subtitle]="subtitle"
    [btn1]="btn1"
    [btn2]="btn2"
    (closeModal)="closeModal()"
    (actionBtn2)="deleteQuoteById()"
  ></app-template-confirmation>
</ng-template>

<ng-template #templateDuplicateConfirmation>
  <app-template-confirmation
    [title]="title"
    [subtitle]="subtitle"
    [btn1]="btn1"
    [btn2]="btn2"
    (closeModal)="closeModal()"
    (actionBtn2)="duplicateQuoteById()"
  ></app-template-confirmation>
</ng-template>
